<!-- ----------------------------------------------------------------------- -->
<!--
name    : HEADER LIST

type    : component

used by : AppSettingsView
          AppEstimateList
          AppInvoiceList
          AppJobList
          AppScheduleView
          AppServiceRequestList
          AppWorkOrderList

uses    : none

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <v-app-bar
    class="header-list-component"
    color="white"
    :style="'z-index:2;position:sticky;top:0'"
    :extension-height="$vuetify.breakpoint.xsOnly ? 72 : 36">
    <v-toolbar-title>
      <span class="headline ml-3 mr-2">
        {{ $vuetify.breakpoint.xsOnly && short_title ? short_title : title }}
      </span>
    </v-toolbar-title>
    <v-spacer />
    <div v-if="btnOptions.length > 0">
      <v-toolbar-title v-for="option in btnOptions" :key="option.name">
        <v-btn
          @click.stop="option.action"
          class="ma-2"
          width="225"
          :color="option.btnColor ? option.btnColor : 'button-primary'">
          <v-icon class="mr-3" v-if="option.icon">{{ option.icon }}</v-icon>
          {{ option.name }}
        </v-btn>
      </v-toolbar-title>
    </div>
  </v-app-bar>
</template>
<script>
  export default {
    name: 'HeaderList',
    props: {
      title: {
        default: '',
        type: String,
      },
      btnOptions: {
        default: () => [],
        type: Array,
      },
      short_title: {
        default: '',
        type: String,
      },
    },
    data() {
      return {};
    },
    mounted() {},
  };
</script>
