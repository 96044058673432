<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP JOB LIST

type    : view

uses    : header-list
          header-view
          main-container
          table-status

route   : /jobs (default route)
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-job-list">
    <header-list title="Jobs" />
    <main-container id="jobsList">
      <v-card>
        <v-card-title>
          <v-btn
            height="28"
            class="button-primary"
            @click="filterdialog = true">
            <span>Filter</span>
            <v-icon right small> mdi-filter </v-icon>
          </v-btn>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="QuickSearch"
            single-line
            hide-details />
        </v-card-title>

        <v-data-table
          id="jobsTable"
          :headers="headers"
          :items="filteredJobs"
          class="elevation-1"
          :search="search"
          sort-by="number"
          sort-desc
          :loading="busy">
          <template v-slot:item="props">
            <tr @click="$router.push('/jobs/view/' + props.item.uuid)">
              <td>JOB-{{ props.item.number.toString().padStart(5, '0') }}</td>
              <td>{{ props.item.client_name || 'Unknown' }}</td>
              <td>{{ props.item.contact_name || 'Unknown' }}</td>
              <td>
                {{
                  props.item.jobsite && props.item.jobsite.name
                    ? props.item.jobsite.name
                    : 'No Site Name'
                }}
              </td>
              <td v-if="props.item.workorders">
                {{ props.item.workorders.length }}
              </td>
              <td v-else>Not Applicable</td>
              <td class="text-center">
                <table-status :status="props.item.phase" />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog v-model="filterdialog" persistent max-width="600px">
        <v-card>
          <header-view title="Filter Jobs" dialog />
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="client_name"
                    label="Client Name Contains"
                    hint="" />
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="contact_name"
                    label="Contact Name Contains"
                    hint="" />
                </v-flex>
                <v-flex xs12>
                  <div id="job-phase-filter">
                    <v-select
                      v-model="phase"
                      :items="filterItems"
                      filled
                      label="Phase" />
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="button-primary" flat @click="applyFilter()">
              Apply
            </v-btn>
            <v-btn class="button-secondary" flat @click="clearFilter()">
              Clear
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </main-container>
  </div>
</template>

<script>
  // components
  import HeaderList from '@/components/header-list';
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';
  import TableStatus from '@/components/table-status';

  // services
  import Jobs from '@/services/Jobs.service.js';
  import Clients from '@/services/Clients.service.js';

  export default {
    name: 'AppJobList',
    components: {
      'header-view': HeaderView,
      'header-list': HeaderList,
      'main-container': MainContainer,
      'table-status': TableStatus,
    },
    data() {
      return {
        filterdialog: false,
        client_name: '',
        contact_name: '',
        phase: '',
        filterItems: [
          {
            text: 'Show All',
            value: '',
          },
          {
            text: 'Service Request',
            value: 'Service Request',
          },
          {
            text: 'Estimate',
            value: 'Estimate',
          },
          {
            text: 'Work',
            value: 'Work',
          },
          {
            text: 'Invoiced',
            value: 'Invoiced',
          },
          {
            text: 'Closed',
            value: 'Closed',
          },
        ],
        search: '',
        busy: false,
        headers: [
          {
            text: 'Job ID',
            align: 'left',
            value: 'number',
          },
          {
            text: 'Client',
            value: 'client_name',
          },
          {
            text: 'Contact',
            value: 'contact_name',
          },
          {
            text: 'Job Site Name',
            value: 'jobsite.name',
          },
          {
            text: 'No. Work Orders',
            value: 'workorders',
          },
          {
            text: 'Phase',
            value: 'phase',
            align: 'center',
          },
        ],
        jobs: [],
        tenant_uuid: this.$auth.userProfile.tenant_uuid,
      };
    },
    computed: {
      // a computed getter
      filteredJobs: function () {
        // `this` points to the vm instance
        return this.jobs.filter(
          function (job) {
            let pass = true;
            if (
              this.client_name &&
              !job.client_name
                .toLowerCase()
                .includes(this.client_name.toLowerCase())
            ) {
              pass = false;
            }
            if (
              this.contact_name &&
              !job.contact_name
                .toLowerCase()
                .includes(this.contact_name.toLowerCase())
            ) {
              pass = false;
            }
            if (this.phase && job.phase != this.phase) {
              pass = false;
            }
            return pass;
          }.bind(this)
        );
      },
    },
    async created() {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      this.busy = true;

      // get all jobs for tenant
      var res_jobs = await Jobs.getJobs(
        {
          tenant_uuid: this.tenant_uuid,
        },
        accessToken
      );

      if (res_jobs) {
        if (res_jobs.length > 0) {
          res_jobs.forEach((job) => {
            job.client_name = null;
            job.contact_name = null;
            job.jobsite = {};
          });

          // get all the client documents based on the list of client id's in the job records
          var client_uuids = res_jobs.map((a) => a.client_uuid);

          // set params
          let client_params = {
            tenant_uuid: this.tenant_uuid,
          };

          if (client_uuids && client_uuids.length > 0) {
            client_params.clientIds = client_uuids;
          }

          // get clients
          var res_clients = await Clients.getClients(
            client_params,
            accessToken
          );

          if (res_clients) {
            for (var i = 0; i < res_jobs.length; i++) {
              for (var j = 0; j < res_clients.length; j++) {
                if (res_jobs[i].client_uuid == res_clients[j].uuid) {
                  res_jobs[i].client_name = res_clients[j].client_name;
                  res_jobs[i].contact_name = res_clients[j].contact_name;
                }
              }

              if (
                res_jobs[i].serviceRequest &&
                res_jobs[i].serviceRequest.job_site_uuid
              ) {
                // get job site
                var res_job_site = await Clients.getJobSite(
                  res_jobs[i].serviceRequest.job_site_uuid,
                  accessToken
                );

                // update job listing with jobsite result
                if (res_job_site) {
                  res_jobs[i].jobsite = res_job_site;
                } else {
                  // console.log( "counld not get associated job site", res_jobs[ i ] )
                }
              } else {
                // console.log( "no service request:", res_jobs[ i ] )
              }
            }

            // set global client
            this.clients = res_clients;
          } else {
            // console.log( "could not get associated clients" )
          }
        } else {
          // console.log( "no jobs found for tenant" )
        }

        // set global jobs value
        this.jobs = res_jobs;
        this.busy = false;
      } else {
        this.busy = false;
        throw Error('Something went wrong getting job list');
      }
    },
    methods: {
      clearFilter: function () {
        this.client_name = '';
        this.contact_name = '';
        this.phase = '';
        this.filterdialog = false;
      },
      applyFilter: function () {
        this.filterdialog = false;
        this.filterJobs();
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .clientList {
    text-align: left;
    width: 40%;
    margin: 0 auto;
  }
</style>
